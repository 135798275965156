import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEOnoindex } from "src/components/seo-noindex"
import { Mobile } from "src/components/device"
import { Carousel } from "src/components/carousel"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  Cell,
  Measure,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
  CellVerticalOffset,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      reimagine_title: string
      reimagine_copy: string
      system_copy: string 
      everything_title: string
      everything_copy: string 
      whole_copy: string
      friends_title: string
      friends_copy: string
      events_title: string
      events_copy: string
      fans_title: string
      fans_copy: string
      hype_title: string
      hype_copy: string
      fair_title: string
      fair_copy: string 
      expertise: string
    }
    images: {
      hero_video_static: any
      ecosystem_static: any
      basic_ticketing_static: any
      grid_1_tickets_static: any
      grid_1_shows_static: any
      grid_1_reserved_static: any
      grid_1_mobile_static: any
      friends_static: any
      three_events_static: any
      grid_2_reservations_static: any
      grid_2_smtickets_static: any
      post_event_static: any
      grid_3_desktop_static: any
      grid_3_waitlist_static: any
    }
    videos: {
      hero_video: any
      ecosystem: any
      basic_ticketing: any
      grid_1_shows: any
      grid_1_reserved: any
      friends: any
      three_events: any
      grid_2_reservations: any
      post_event: any
      grid_3_desktop: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEOnoindex
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.hero_video}
            image={images.hero_video_static}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.reimagine_title}</Headline>
          <Paragraph>{copy.reimagine_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.ecosystem}
            image={images.ecosystem_static}
          />
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.system_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.everything_title}</Headline>
          <Paragraph>{copy.everything_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.basic_ticketing}
            image={images.basic_ticketing_static}
          />
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.whole_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp0: "$1", bp1: "$2", bp3: "$2" }}>
            <Col>
              <ImgFluid image={images.grid_1_tickets_static} />
            </Col>
            <Col 
              start={{ bp0: "$1", bp1: "$2" }}
              end={{ bp0: "$2", bp1: "$3" }}> 
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_1_shows}
                image={images.grid_1_shows_static}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_1_reserved}
                image={images.grid_1_reserved_static}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_1_mobile_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.friends_title}</Headline>
          <Paragraph>{copy.friends_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.friends}
            image={images.friends_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.events_title}</Headline>
          <Paragraph>{copy.events_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.three_events}
            image={images.three_events_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.fans_title}</Headline>
          <Paragraph>{copy.fans_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp0: "$1", bp1: "$2", bp3: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                video={videos.grid_2_reservations}
                image={images.grid_2_reservations_static}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_2_smtickets_static} />
            </Col>
          </Grid>
        </Block>
      </Container>


      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.hype_title}</Headline>
          <Paragraph>{copy.hype_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.post_event}
            image={images.post_event_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.fair_title}</Headline>
          <Paragraph>{copy.fair_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp0: "$1", bp1: "$2", bp3: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                video={videos.grid_3_desktop}
                image={images.grid_3_desktop_static}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_3_waitlist_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "spotify-live" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        reimagine_title
        reimagine_copy
        system_copy
        everything_title
        everything_copy
        whole_copy
        friends_title
        friends_copy
        events_title
        events_copy
        fans_title
        fans_copy
        hype_title
        hype_copy
        fair_title
        fair_copy
        expertise
      }
      images {
        hero_video_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        ecosystem_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        basic_ticketing_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_tickets_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_shows_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_reserved_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_mobile_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        friends_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        three_events_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_reservations_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_smtickets_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        post_event_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_desktop_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_waitlist_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }

      }
      videos {
        hero_video {
          src {
            src_large
            src_medium
            src_small
          }
        }
        ecosystem {
          src {
            src_large
            src_medium
            src_small
          }
        }
        basic_ticketing {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_reserved {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_shows {
          src
        }
        friends {
          src {
            src_large
            src_medium
            src_small
          }
        }
        three_events {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_2_reservations {
          src {
            src_large
            src_medium
            src_small
          }
        }
        post_event {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_3_desktop {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`


